import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { Wait } from 'modules/Pdf/components/Wait'
import axios from 'axios'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { ICostCoverageSearchResult } from 'contracts/costCoverages/interfaces/ICostCoverageSearchResult'
import { ICostCoveragePayer } from 'contracts/costCoverages/interfaces/ICostCoveragePayer'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { QRCode } from 'components/QrCode'

export const LfgbAktendeckblattBewohnerakte: React.FC<DocumentTemplateProps> = (
  props,
) => {
  const [family, setFamily] = React.useState<IFamily | null>(null)
  const [members, setMembers] = React.useState<IResidentSearchResult[] | null>(null)
  const [payers, setPayers] = React.useState<ICostCoveragePayer[] | null>(null)
  const [coverages, setCoverages] = React.useState<ICostCoverageSearchResult[] | null>(
    null,
  )
  const [building, setBuilding] = React.useState<IBuilding | null>(null)
  const [compound, setCompound] = React.useState<ICompound | null>(null)

  React.useEffect(() => {
    const load = async () => {
      const [f, m, c, p] = await Promise.all([
        axios.get<any>(
          `/api/${props.resident.instanceId}/families/${props.resident.familyId}`,
        ),
        axios.get<any>(
          `/api/${props.resident.instanceId}/residentSearchResults?familyId=${props.resident.familyId}&fields=bookings`,
        ),
        axios.get<any>(
          `/api/${props.resident.instanceId}/residents/${props.resident.id}/costCoverageSearchResults`,
        ),
        axios.get<any>(`/api/${props.resident.instanceId}/costCoveragePayers`),
      ])
      const stay = m.data.resources
        .find((m) => m.id === props.resident.id)
        ?.data?.bookings?.find(
          (b) => b.timeline === 'present' && b.type === 'internal-residence',
        )
      if (stay) {
        const rm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
        )
        const bl = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
        )
        const cm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
        )
        setBuilding(bl.data)
        setCompound(cm.data)
      }
      setFamily(f.data)
      setMembers(m.data.resources)
      setCoverages(c.data.resources)
      setPayers(p.data.resources)
    }
    load()
    // eslint-disable-next-line
  }, [])

  let buildingLabel = ''

  if (compound?.label === building?.label) {
    buildingLabel = compound?.label || ''
  } else {
    buildingLabel = compound && building ? compound?.label + ' / ' + building?.label : ''
  }

  if (!family || !members || !coverages || !payers) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <Wait />
      </div>
    )
  }

  if (
    props.resident.familyRelation !== 'Haushaltsvorstand' ||
    family.hv !== props.resident.id
  ) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <div className='border-2 border-red-500 p-10 text-red-500 rounded-md text-center'>
          Dieses Dokument kann nur für Haushaltsvorstände erstellt werden.
        </div>
      </div>
    )
  }

  const stay = members
    .find((m) => m.id === props.resident.id)
    ?.data?.bookings?.find(
      (b) => b.timeline === 'present' && b.type === 'internal-residence',
    )
  const coverage = coverages.find(
    (c) =>
      c.coversCurrentResident &&
      c.compound.id === compound?.id &&
      dayjs().isSameOrAfter(c.beginDate, 'day') &&
      dayjs().isSameOrBefore(c.endDate, 'day'),
  )
  const payer = payers.find((p) => p.id === coverage?.payerId)
  return (
    <>
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        {/* Header */}
        <div className='flex'>
          <div className='flex-[0_0_100px]'>
            <QRCode
              qrCodeId={props.model.values.qrCode || '0'}
              pageNumber={1}
              totalNumberOfPages={1}
              instanceId={props.instanceId}
              scale={3}
            />
          </div>
          <div className='flex-auto pt-[2px]'>
            Landesbetrieb für Gebäudebewirtschaftung Berlin
            <br />
            Betriebsteil B (LfG-B)
            <br />
            {buildingLabel}
          </div>
          <img className={styles.logo} src={logo} alt='' />
        </div>
        <br />
        <div className='pt-3 text-3xl'>Aktendeckblatt</div>

        <div className='text-lg'>
          <div className='flex mt-4'>
            <div className='flex-content'>Name:</div>
            <div className='flex-auto border-b border-black truncate pl-2'>{`${props.resident.lastName}, ${props.resident.firstName}`}</div>
            <div className='flex-content ml-6'>Einzug am:</div>
            <div
              className='flex-content border-b border-black truncate px-2'
              style={{ minWidth: 100 }}
            >
              {stay ? dayjs(stay.beginAt).format('DD.MM.YYYY') : ''}
            </div>
          </div>

          <div className='flex text-lg mt-2'>
            <div className='flex-content'>Zimmer:</div>
            <div className='flex-auto border-b border-black truncate pl-2'>
              {(stay?.label || '').split('/').pop()!.trim()}
            </div>
            <div className='flex-content ml-6'>Personen:</div>
            <div className='flex-[0_0_50px] border-b border-black truncate pl-2'>
              {members.length}
            </div>
            <div className='flex-content ml-6'>Kostenträger:</div>
            <div className='flex-content max-w-[200px] min-w-[50px] border-b border-black truncate pl-2'>
              {payer?.abbreviation || ''}
            </div>
          </div>

          <div
            className='grid grid-cols-[450px_auto] border border-black text-base mt-4'
            style={{ lineHeight: '23px' }}
          >
            <div className='px-1 border-b-2 border-t border-x border-black bg-gray-300'>
              Einzugsdokumente
            </div>
            <div className='px-1 text-sm text-center border-b-2 border-t border-r border-black bg-gray-300'>
              bei Vorliegen abhaken <i className='fas fa-check' />
            </div>
            <div className='px-1 border-b border-x border-black'>
              Kostenübernahmen <span className='text-sm'>(Originale)</span>
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Anmeldebestätigung Land Berlin{' '}
              <span className='text-sm'>(Bürgerämter)</span>
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x  border-black'>
              Belehrung zum Datenschutz
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x  border-black'>
              Belehrung zu Hausordnung und Brandschutz
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x  border-black'>
              Belehrung zum Umgang mit zurückgelassener Habe
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x  border-black'>
              Nachweis über TBC-Untersuchung
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x  border-black'>
              Nachweis über Masern-Impfung
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-x  border-b-2 border-black'>
              Elternfürsorgepflicht <span className='text-sm'>(sofern relevant)</span>
            </div>
            <div className='px-1 border-b-2 border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Checkliste Einzug / Auszug
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-x  border-b border-black'>Schlüsselprotokoll</div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-x  border-b border-black'>Schlüsselprotokoll</div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-x border-black'>
              Empfangsbestätigung der Erstausstattung
            </div>
            <div className='px-1 border-r border-black'>&nbsp;</div>
            <div className='px-1 border-y-2 border-x border-black bg-gray-300'>
              Weitere Dokumente
            </div>
            <div className='px-1 text-sm text-center border-y-2 border-r border-black bg-gray-300'>
              bei Vorliegen abhaken <i className='fas fa-check' />
            </div>
            <div className='px-1 border-b border-x border-black'>Krankenversicherung</div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>BAMF, LEA</div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Erst-, Folgeantragsteller
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Schweigepflichtsentbindung{' '}
              <span className='text-sm'>(nur zweckgebunden zu verwenden!)</span>
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Personendatenänderungen{' '}
              <span className='text-sm'>(Namenswechsel, Geb.dat., Ehe etc.)</span>
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Ankündigung der Abmeldung
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Verlegung <span className='text-sm'>(Antrag, Antwort)</span>
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Abwesenheitsnachweise{' '}
              <span className='text-sm'>(z.B. bei KH-Aufenthalt/ Ortsabwesenheit)</span>
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Korrespondenz, Betreuer*in, Gericht o.Ä.
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Protokoll zur zurückgelassenen Habe
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Ermahnung, Abmahnung, Hausverbot
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'>
              Jugendamt, Schule, Kita
            </div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'></div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'></div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-b border-x border-black'></div>
            <div className='px-1 border-b border-r border-black'>&nbsp;</div>
            <div className='px-1 border-y border-x border-black col-span-full h-[80px]'>
              Bemerkung:
            </div>
          </div>
        </div>

        {/* Footer */}
        <div
          className='grid grid-cols-2 gap-[360px] text-xs text-gray-500 absolute'
          style={{ bottom: 40, fontSize: 9 }}
        >
          <div>Stand: 09.08.2023</div>
          <div className='text-right'>
            Brunnenstraße 110 C, 13355 Berlin
            <br />
            Tel. +49 30 213 099 101
            <br />
            info@lfg-b.de
          </div>
        </div>
      </div>
    </>
  )
}
