import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import axios from 'axios'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { Message } from 'components/Message'
import { IMapping } from 'contracts/general/interfaces/IMapping'
import { IImportData } from 'contracts/migrations/interfaces/IImportData'
import { IMigration } from 'contracts/migrations/interfaces/IMigration'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { MappingRow } from 'modules/Residents/modules/Migration/components/MappingRow'
import * as React from 'react'

interface Props {
  migration: IMigration
  column: IImportData['columns'][0]
  onClose: () => void
}

@observer
export class MigrationEnumAssignment extends React.Component<Props, {}> {
  @observable private loading = true
  @observable private error: string | null = null
  @observable private rows: IMapping[] | null = null
  @observable.ref private options: InputSelectOption[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.load()
  }

  private load = async () => {
    try {
      const response = await axios.get<any>(
        `/api/${this.props.migration.instanceId}/imports/${
          this.props.migration.id
        }/enumMappings?key=${encodeURIComponent(
          this.props.column.key,
        )}&from=${encodeURIComponent(this.props.column.from)}`,
        { withCredentials: true },
      )
      runInAction(() => {
        this.loading = false
        this.rows = response.data.resources
        this.options = response.data.metadata.options
        if (!this.options.some((o) => o.value === '')) {
          this.options.unshift({ label: '', value: '' })
        }
      })
    } catch (e) {
      console.log(e)
      runInAction(() => {
        this.loading = false
        this.error = 'Fehler beim Laden der Zuordnungen.'
      })
    }
  }

  private onSubmit = async () => {
    if (!this.rows) {
      return
    }
    try {
      runInAction(() => {
        this.loading = true
      })
      await axios.post<any>(
        `/api/${this.props.migration.instanceId}/imports/${
          this.props.migration.id
        }/enumMappings?key=${encodeURIComponent(this.props.column.key)}`,
        { mappings: this.rows, from: this.props.column.from },
        { withCredentials: true },
      )
      this.props.onClose()
    } catch (e) {
      console.log(e)
      runInAction(() => {
        this.loading = false
        this.error = 'Fehler beim Speichern der Zuordnungen.'
      })
    }
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {this.props.column.to}
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        <div className='mt-6'>
          <div className='mb-4 text-gray-600'>
            Die Migration akzeptiert für die ausgewählte Spalte nur bestimmte Werte. Hier
            können Sie Rechtschreibfehler und Abweichungen korrigieren und für jeden
            eingegebenen Wert den entsprechenden korrekten Wert in Inuv auswählen.
          </div>
          <div className='bg-gray-100 px-6 pb-2 pt-5 border-t border-gray-300 -mx-6'>
            {this.rows?.map((r, i) => (
              <MappingRow
                key={i}
                row={r}
                type={this.props.column.type}
                label={this.props.column.to}
                options={this.options}
              />
            ))}
          </div>
        </div>

        <div className='mt-auto border-t border-gray-300 flex-content text-right -mx-6 p-6 -mb-6 z-10 bottom-0 sticky bg-white'>
          <Button
            disabled={this.loading}
            color='secondary'
            outline
            onClick={() => this.props.onClose()}
          >
            Abbrechen
          </Button>
          <Button disabled={this.loading} className='ml-3' onClick={this.onSubmit}>
            Speichern
          </Button>
        </div>
        {this.loading && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
