import { IResident } from 'contracts/residents/interfaces/IResident'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { Button } from 'components/Form/components/Button'
import { box } from 'services/box'
import { hermes } from '@byll/hermes'
import { makeObservable, observable, runInAction } from 'mobx'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { ResidentProfileExportDropdown } from './components/ResidentProfileExportDropdown'

interface Props {
  resident: IResident
}

@observer
export class OverviewDelete extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private onArchive = async () => {
    if (this.context.permissions.resident_deletePerson < 1) {
      await box.alert(
        'Archivieren',
        'Sie haben keine Berechtigung, Bewohner zu archivieren.',
        { confirm: 'OK', color: 'danger' },
      )
      return
    }
    if (
      !(await box.alert(
        'Archivieren',
        'Möchten Sie diesen Bewohner wirklich archivieren?',
        { confirm: 'Ja', cancel: 'Abbrechen', color: 'danger' },
      ))
    ) {
      return
    }
    await hermes.delete(
      `/api/${this.context.instance.id}/residents/${this.props.resident.id}`,
      { headers: { 'X-Archive': 'yes' } },
    )
  }

  private onDelete = async () => {
    if (this.context.permissions.resident_deletePerson < 2) {
      await box.alert('Löschen', 'Sie haben keine Berechtigung, Bewohner zu löschen.', {
        confirm: 'OK',
        color: 'danger',
      })
      return
    }
    if (
      !(await box.alert(
        'Löschen',
        'Möchten Sie diesen Bewohner wirklich unwiderruflich löschen?',
        { confirm: 'Ja', cancel: 'Abbrechen', color: 'danger' },
      ))
    ) {
      return
    }
    await hermes.delete(
      `/api/${this.context.instance.id}/residents/${this.props.resident.id}`,
      { headers: { 'X-Archive': 'no' } },
    )
  }

  private onRestoreArchivedResident = async () => {
    if (this.context.permissions.resident_deletePerson < 1) {
      await box.alert(
        'Wiederherstellen',
        'Sie haben keine Berechtigung, einen archivierten Bewohner wiederherzustellen.',
        { confirm: 'OK', color: 'danger' },
      )
      return
    }
    if (
      !(await box.alert(
        'Wiederherstellen',
        'Möchten Sie diesen Bewohner wirklich wiederherstellen?',
        { confirm: 'Ja', cancel: 'Abbrechen', color: 'danger' },
      ))
    ) {
      return
    }
    await hermes.patch(
      `/api/${this.context.instance.id}/residents/${this.props.resident.id}`,
      { deletedAt: null },
    )
  }

  private onExport = async (type: 'resident' | 'family') => {
    if (this.loading) {
      return
    }
    if (!this.context.permissions.resident_exportProfile) {
      await box.alert(
        'Exportieren',
        'Sie haben keine Berechtigung, Bewohnerstammdaten zu exportieren.',
        { confirm: 'OK', color: 'danger' },
      )
      return
    }
    runInAction(() => {
      this.loading = true
    })
    await createAndDownloadReport(
      'resident-profile-export',
      this.context.instance.id,
      {
        type,
        residentId: this.props.resident.id,
        familyId: this.props.resident.familyId,
      },
      `Stammdaten.pdf`,
    )
    runInAction(() => {
      this.loading = false
    })
  }

  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div className='pr-12 pt-4 text-right' style={{ flex: '0 0 200px' }}>
          <span className='text-gray-900 text-lg'>Datensatz</span>
          <br />
          <span className='text-sm text-gray-400'>Bewohner exportieren und löschen</span>
        </div>

        <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
          <div className='pl-2'>
            <div className='flex mb-4 leading-[38px]'>
              <ResidentProfileExportDropdown
                onExport={this.onExport}
                loading={this.loading}
              />
              <span className='ml-4 text-gray-800'>PDF der Bewohnerdaten</span>
            </div>
            {!this.props.resident.deletedAt && (
              <div>
                <Button className='mr-2' color='danger' onClick={this.onArchive}>
                  Bewohner archivieren
                </Button>
                <span className='ml-2 text-gray-800'>Ins Archiv verschieben</span>
              </div>
            )}
            {this.props.resident.deletedAt && (
              <div>
                <Button
                  className='mr-2'
                  color='success'
                  onClick={this.onRestoreArchivedResident}
                >
                  Bewohner wiederherstellen
                </Button>
                <span className='ml-2 text-gray-800'>
                  Aus dem Archiv wiederherstellen
                </span>
              </div>
            )}
            <div className='mt-4'>
              <Button className='mr-2' color='danger' onClick={this.onDelete}>
                Bewohner löschen
              </Button>
              <span className='ml-2 text-gray-800'>
                Unwiderrunflich aus der Datenbank löschen
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
