import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { makeObservable, observable, runInAction } from 'mobx'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { PaperInputText } from 'components/PaperInputs/PaperInputText'
import { dayjs } from 'helpers/dayjs'
import { PaperInputTextarea } from 'components/PaperInputs/PaperInputTextarea'
import axios from 'axios'
import { observer } from 'mobx-react'
import { QRCode } from 'components/QrCode'

@observer
export class LfgbEntbindungVonDerSchweigepflicht extends React.Component<
  DocumentTemplateProps,
  {}
> {
  @observable private buildingLabel: string = ''

  constructor(props: DocumentTemplateProps) {
    super(props)
    makeObservable(this)
    runInAction(() => {
      props.model.values.date = props.model.values.date || dayjs().format('DD.MM.YYYY')
      props.model.values.residentName =
        props.model.values.residentName ||
        `${props.resident.firstName} ${props.resident.lastName}`
      props.model.values.residentName2 = ''
      props.model.values.address = props.model.values.address || ''
      props.model.values.dateOfBirth =
        props.model.values.dateOfBirth ||
        (props.resident.dateOfBirth
          ? dayjs(props.resident.dateOfBirth).format('DD.MM.YYYY')
          : '')
      props.model.values.dateOfBirth2 = ''
      props.model.values.employeeName1 = props.model.values.employeeName1 || ''
      props.model.values.employeeName2 = props.model.values.employeeName2 || ''
      props.model.values.accommodation = props.model.values.accommodation || ''
      props.model.values.jugendamtCheckbox = props.model.values.jugendamtCheckbox || false
      props.model.values.jobcenterCheckbox = props.model.values.jobcenterCheckbox || false
      props.model.values.krankenhauseCheckbox =
        props.model.values.krankenhauseCheckbox || false
      props.model.values.schuleCheckbox = props.model.values.schuleCheckbox || false
      props.model.values.gerichtCheckbox = props.model.values.gerichtCheckbox || false
      props.model.values.othersCheckbox = props.model.values.othersCheckbox || false
      props.model.values.othersCheckboxLabel =
        props.model.values.othersCheckboxLabel || ''
      props.model.values.untilDate = props.model.values.untilDate || ''
      props.model.values.zweck = props.model.values.zweck || ''
      props.model.values.personenDaten = props.model.values.personenDaten || ''
    })
  }

  componentDidMount(): void {
    this.loadData()
  }

  private async loadData() {
    const [r] = await Promise.all([
      axios.get<any>(
        `/api/${this.props.resident.instanceId}/residentSearchResults?residentIds=${this.props.resident.id}&fields=bookings`,
      ),
    ])
    if (!Array.isArray(r.data.resources) || r.data.resources.length !== 1) {
      throw new Error('Not found')
    }
    const stay = r.data.resources[0]?.data.bookings?.find(
      (b) => b.timeline === 'present' && b.type === 'internal-residence',
    )
    if (stay) {
      const rm = await axios.get<any>(
        `/api/${this.props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
      )
      const bl = await axios.get<any>(
        `/api/${this.props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
      )
      const cp = await axios.get<any>(
        `/api/${this.props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
      )

      if (cp.data.label === bl.data.label) {
        runInAction(() => (this.buildingLabel = cp.data.label || ''))
      } else {
        runInAction(
          () =>
            (this.buildingLabel =
              cp.data && bl.data ? cp.data.label + ' / ' + bl.data.label : ''),
        )
      }
    }
  }

  render() {
    return (
      <>
        {/* Page 1/3 */}
        <div className={`${styles.page} ${this.props.readOnly ? '' : 'shadow-sm mb-3'}`}>
          <div className='absolute top-[40px] left-[100px] right-[100px] flex'>
            <div className='flex-[0_0_100px]'>
              <QRCode
                qrCodeId={this.props.model.values.qrCode || '0'}
                pageNumber={1}
                totalNumberOfPages={1}
                instanceId={this.props.instanceId}
                scale={3}
              />
            </div>
            <div className='flex-auto pt-[2px]'>
              Landesbetrieb für Gebäudebewirtschaftung Berlin
              <br />
              Betriebsteil B (LfG-B)
              <br />
              {this.buildingLabel}
            </div>
          </div>
          <img className={styles.logo} src={logo} alt='' />
          <div className='pt-4 text-2xl'>
            Entbindung von der Schweigepflicht (gem. §203 StGB)
          </div>
          <div className='text-base'>(nur zweckgebunden zu verwenden!)</div>
          <div className='text-base'>
            <div className='mt-3'>
              Mit diesem Schreiben entbinde ich /entbinden wir (z.B. als Familie)
            </div>
            <div className='mt-3 grid grid-cols-2'>
              <PaperInputText
                className='mb-[1px]'
                model={this.props.model}
                name='residentName'
                minWidth={50}
                maxWidth={600}
                placeholder='Vor- und Nachname'
                readOnly={!!this.props.readOnly}
              />
              <PaperInputText
                model={this.props.model}
                name='dateOfBirth'
                minWidth={50}
                maxWidth={120}
                placeholder='Geburtsdatum'
                readOnly={!!this.props.readOnly}
              />
            </div>
            <div className='mt-3 grid grid-cols-2'>
              <PaperInputText
                className='mb-[1px]'
                model={this.props.model}
                name='residentName2'
                minWidth={50}
                maxWidth={600}
                placeholder='ggf. 2. Familienmitglied'
                readOnly={!!this.props.readOnly}
              />
              <PaperInputText
                model={this.props.model}
                name='dateOfBirth2'
                minWidth={50}
                maxWidth={120}
                placeholder='Geburtsdatum'
                readOnly={!!this.props.readOnly}
              />
            </div>

            <div className='mt-5'>
              Herrn/Frau{' '}
              <PaperInputText
                className='inline-block'
                model={this.props.model}
                name='employeeName1'
                minWidth={10}
                maxWidth={224}
                placeholder='Name der*des Mitarbeiter*s'
                readOnly={!!this.props.readOnly}
              />{' '}
              aus{' '}
              <PaperInputText
                className='inline-block'
                model={this.props.model}
                name='accommodation'
                minWidth={100}
                maxWidth={300}
                placeholder='Name und Stempel der Unterkunft'
                readOnly={!!this.props.readOnly}
              />
            </div>

            <div className='mt-5'>
              <div className='flex flex-row'>
                <div className='flex-content'>gegenüber dem/den:</div>
                <div className='flex-auto ml-2 gap-2 grid grid-cols-3'>
                  <InputCheckbox
                    model={this.props.model}
                    name='jugendamtCheckbox'
                    label='Jugendamt'
                    type='checkbox'
                  />
                  <InputCheckbox
                    model={this.props.model}
                    name='jobcenterCheckbox'
                    label='Jobcenter'
                    type='checkbox'
                  />
                  <InputCheckbox
                    model={this.props.model}
                    name='krankenhausCheckbox'
                    label='Krankenhaus'
                    type='checkbox'
                  />
                  <InputCheckbox
                    model={this.props.model}
                    name='schuleCheckbox'
                    label='Schule'
                    type='checkbox'
                  />
                  <InputCheckbox
                    model={this.props.model}
                    name='gerichtCheckbox'
                    label='Gericht'
                    type='checkbox'
                  />
                  <div className='flex'>
                    <InputCheckbox
                      className='flex-content'
                      model={this.props.model}
                      name='othersCheckbox'
                      label=''
                      type='checkbox'
                    />
                    <PaperInputText
                      className='flex-auto'
                      inputClassName={styles.checkboxLabel}
                      name='othersCheckboxLabel'
                      model={this.props.model}
                      readOnly={!!this.props.readOnly}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-5'>
              Vertreten durch{' '}
              <PaperInputText
                className='inline-block text-sm'
                model={this.props.model}
                name='employeeName2'
                minWidth={10}
                maxWidth={320}
                placeholder='Name der*des Mitarbeiter*s der o.g. Stelle'
                readOnly={!!this.props.readOnly}
              />{' '}
              von der Schweigepflicht.
            </div>

            <div className='mt-5'>
              Diese Erklärung gilt bis
              <PaperInputText
                className='inline-block mx-1'
                name='untilDate'
                model={this.props.model}
                minWidth={50}
                maxWidth={120}
                placeholder='TT.MM.JJJJ'
                readOnly={!!this.props.readOnly}
              />
              und dient folgendem Zweck:
            </div>

            <PaperInputTextarea
              className='mt-1'
              model={this.props.model}
              name='zweck'
              placeholder='Klicken oder tippen Sie hier, um Text einzugeben'
              maxRows={4}
              minRows={1}
              readOnly={!!this.props.readOnly}
            />

            <p className='mt-5'>
              Und bezieht sich im Einzelnen auf folgende Unterlagen bzw. personenbezogene
              Daten:
            </p>

            <PaperInputTextarea
              model={this.props.model}
              name='personenDaten'
              placeholder='Klicken oder tippen Sie hier, um Text einzugeben'
              maxRows={4}
              minRows={1}
              readOnly={!!this.props.readOnly}
            />

            <p className='mt-5'>
              Die Entbindung von der Schweigepflicht berechtigt die*den oben bestimmte*n
              Mitarbeiter*in nicht, die enthaltenen Informationen gegenüber dritten
              Personen zu verwenden.
            </p>

            <p className='mt-5'>
              Mir ist bekannt, dass ich diese Erklärung zur Entbindung von der
              Schweigepflicht jederzeit mit Wirkung für die Zukunft widerrufen kann.
            </p>

            <div className='grid gap-16 grid-cols-3 mt-8'>
              <div>
                <div className='border-b border-black'>
                  {dayjs().format('DD.MM.YYYY')}
                </div>
                <div style={{ fontSize: '10px', lineHeight: '14px' }}>Datum</div>
              </div>

              <div>
                <div className='border-b border-black truncate'>
                  {this.props.resident.firstName}, {this.props.resident.lastName}&nbsp;
                </div>
                <div style={{ fontSize: '10px', lineHeight: '14px' }}>
                  Bewohner*in: Vorname, Name
                </div>
              </div>

              <div>
                <div className='border-b border-black truncate'>&nbsp;</div>
                <div style={{ fontSize: '10px', lineHeight: '14px' }}>Unterschrift</div>
              </div>
            </div>

            <div
              className='flex justify-between w-[640px] text-xs text-gray-500 absolute'
              style={{ bottom: 40, fontSize: 9 }}
            >
              <div>Stand 09.08.2022</div>
              <div className='text-right'>
                Brunnenstraße 110 C, 13355 Berlin
                <br />
                Tel. +49 30 213 099 101
                <br />
                info@lfg-b.de
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
