import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import { Wait } from 'modules/Pdf/components/Wait'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import axios from 'axios'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import dayjs from 'dayjs'
import { QRCode } from 'components/QrCode'

export const LfgbKenntnisnahmeMasern: React.FC<DocumentTemplateProps> = (props) => {
  const [residentSearchResult, setResidentSearchResult] =
    React.useState<IResidentSearchResult | null>(null)
  const [building, setBuilding] = React.useState<IBuilding | null>(null)
  const [compound, setCompound] = React.useState<ICompound | null>(null)

  React.useEffect(() => {
    const load = async () => {
      const r = await axios.get<any>(
        `/api/${props.resident.instanceId}/residentSearchResults?residentIds=${props.resident.id}&fields=bookings`,
      )
      if (!Array.isArray(r.data.resources) || r.data.resources.length !== 1) {
        throw new Error('Not found')
      }
      const stay = r.data.resources[0]?.data.bookings?.find(
        (b) => b.timeline === 'present' && b.type === 'internal-residence',
      )
      if (stay) {
        const rm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
        )
        const bl = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
        )
        const cp = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
        )
        setCompound(cp.data)
        setBuilding(bl.data)
      }
      setResidentSearchResult(r.data.resources[0])
    }
    load()
    // eslint-disable-next-line
  }, [])

  let buildingLabel = ''

  if (compound?.label === building?.label) {
    buildingLabel = compound?.label || ''
  } else {
    buildingLabel = compound && building ? compound?.label + ' / ' + building?.label : ''
  }

  if (!residentSearchResult) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <Wait />
      </div>
    )
  }

  return (
    <>
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        {/* Header */}
        <div className='absolute top-[40px] left-[100px] right-[100px] flex'>
          <div className='flex-[0_0_100px]'>
            <QRCode
              qrCodeId={props.model.values.qrCode || '0'}
              pageNumber={1}
              totalNumberOfPages={1}
              instanceId={props.instanceId}
              scale={3}
            />
          </div>
          <div className='flex-auto pt-[2px]'>
            Landesbetrieb für Gebäudebewirtschaftung Berlin
            <br />
            Betriebsteil B (LfG-B)
            <br />
            {buildingLabel}
          </div>
        </div>
        <img className={styles.logo} src={logo} alt='' />

        {/* Title */}
        <div className='text-2xl mt-6 text-center'>
          Bestätigung der Kenntnisnahme der Masern-Impfpflicht
        </div>

        {/* Text */}
        <div className='mt-12' style={{ fontSize: '16px' }}>
          <div className='mt-8'>
            Mit meiner Unterschrift bestätige ich, {props.resident.firstName}{' '}
            {props.resident.lastName} die Kenntnisnahme der Informationen zur
            Masern-Impfpflicht.
          </div>
          <div className='mt-8'>
            Ich habe die Frist für die Abgabe eines Nachweises über einen vollständigen
            Impfschutz gegen Masern bis zum{' '}
            <span className='underline relative'>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className='absolute -bottom-4 left-10 text-xs'>Datum</span>
            </span>{' '}
            zur Kenntnis genommen.
          </div>
          <div className='grid gap-16 grid-cols-3 mt-12'>
            <div>
              <div className='border-b border-black text-center'>
                {dayjs().format('DD.MM.YYYY')}
              </div>
              <div className='text-sm text-center'>Datum</div>
            </div>

            <div>
              <div className='border-b border-black truncate'>&nbsp;</div>
              <div className='text-sm text-center'>Haushaltsvorstand</div>
            </div>

            <div>
              <div className='border-b border-black truncate'>&nbsp;</div>
              <div className='text-sm text-center'>{`${props.user.lastName}, ${props.user.firstName}`}</div>
            </div>
          </div>
          <div className='border-b border-black w-full my-12'></div>
          <div>
            <div className='font-bold'>Information</div>
            <p className='text-sm'>
              Laut Masernschutzgesetz (www.masernschutz.de/) und Infektionsschutzgesetz
              (§20 Absatz) müssen BW einer Gemeinschaftsunterkunft, die nach 1970 geboren
              sind und das 2. Lebensjahr vollendet haben, zwei Masernimpfungen vorweisen
              (sie sind somit ausreichend gegen Masern geimpft) bzw. immun nach
              durchgemachter Maserninfektion sein (Nachweis erfolgt durch
              Antikörper-Titer-Bestimmung im Blut).
            </p>
            <p className='text-sm mt-3'>
              Normalerweise erfolgt die 1. Impfung im Ankunftszentrum. Die 2. Impfung muss
              zeitnah nach der 1. Impfung erfolgen: frühestens nach 4 Wochen, eine
              maximale Dauer gibt es nicht.
            </p>
            <p className='text-sm mt-3'>
              Wird ein entsprechender Nachweis nicht nach Ablauf von 8 Wochen vorgelegt,
              informiert die UL das zuständige Gesundheitsamt. Dieses gibt das weitere
              Vorgehen vor. Bei weiterem Unterlassen reicht der Ermessensspielraum des
              Gesundheitsamtes vom Buß- bzw. Zwangsgeld bis hin zum Betretungsverbot der
              Unterkunft.
            </p>
            <p className='text-sm mt-3'>
              Beachte: eine Schwangerschaft (Nachweis per Mutterpass bzw. ärztl. Attest)
              schließt eine Impfung vorübergehend aus. Nach Geburt ist eine Impfung wieder
              möglich, Stillen stellt keine Gegenanzeige dar.
            </p>
            <p className='text-sm mt-3'>
              Impfungen sind möglich: beim Hausarzt, in der Impfstelle des
              Gesundheitsamtes sowie im Rahmen von Impfaktionen in der Unterkunft
            </p>
            <p className='text-sm mt-3'>
              Kontakt: zuständiges Gesundheitsamt, ggf. dessen Impfstelle.
            </p>
          </div>
          <div
            className='flex justify-between w-[640px] text-xs text-gray-500 absolute'
            style={{ bottom: 40, fontSize: 9 }}
          >
            <div>Stand 09.08.2022</div>
            <div className='text-right'>
              Brunnenstraße 110 C, 13355 Berlin
              <br />
              Tel. +49 30 213 099 101
              <br />
              info@lfg-b.de
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
