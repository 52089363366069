import { observer } from 'mobx-react'
import * as React from 'react'
import { CircularProgressbar } from 'components/CircularProgressbar'
import { Button } from 'components/Form/components/Button'

interface Props {
  onCancel: () => void
  upload: { percentage: number }
}

@observer
export class CircularUploadProgress extends React.Component<Props, {}> {
  render() {
    return (
      <div
        className='flex-content m-auto p-6'
        style={{ width: 200, position: 'sticky', top: 246 }}
      >
        <CircularProgressbar
          percentage={
            this.props.upload.percentage === 1 ? undefined : this.props.upload.percentage
          }
          strokeWidth={4}
        />
        <div className='text-center mt-6'>
          <Button color='primary' outline onClick={this.props.onCancel}>
            Upload abbrechen
          </Button>
        </div>
      </div>
    )
  }
}
