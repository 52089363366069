import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import { dayjs } from 'contracts/general/helpers/dayjs'
import axios from 'axios'
import { Wait } from 'modules/Pdf/components/Wait'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { QRCode } from 'components/QrCode'

export const LfgbEinwilligungDatenschutz: React.FC<DocumentTemplateProps> = (props) => {
  const [residentSearchResult, setResidentSearchResult] =
    React.useState<IResidentSearchResult | null>(null)
  const [building, setBuilding] = React.useState<IBuilding | null>(null)
  const [compound, setCompound] = React.useState<ICompound | null>(null)

  React.useEffect(() => {
    const load = async () => {
      const [r] = await Promise.all([
        axios.get<any>(
          `/api/${props.resident.instanceId}/residentSearchResults?residentIds=${props.resident.id}&fields=bookings`,
        ),
      ])
      if (!Array.isArray(r.data.resources) || r.data.resources.length !== 1) {
        throw new Error('Not found')
      }
      const stay = r.data.resources[0]?.data.bookings?.find(
        (b) => b.timeline === 'present' && b.type === 'internal-residence',
      )
      if (stay) {
        const rm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
        )
        const bl = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
        )
        const cp = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
        )
        setCompound(cp.data)
        setBuilding(bl.data)
      }
      setResidentSearchResult(r.data.resources[0])
    }
    load()
    // eslint-disable-next-line
  }, [])

  let buildingLabel = ''

  if (compound?.label === building?.label) {
    buildingLabel = compound?.label || ''
  } else {
    buildingLabel = compound && building ? compound?.label + ' / ' + building?.label : ''
  }

  if (!residentSearchResult) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <Wait />
      </div>
    )
  }

  return (
    <>
      {/* Page 1/2 */}
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm mb-3'}`}>
        <div className='absolute top-[40px] left-[100px] right-[100px] flex'>
          <div className='flex-[0_0_100px]'>
            <QRCode
              qrCodeId={props.model.values.qrCode || '0'}
              pageNumber={1}
              totalNumberOfPages={2}
              instanceId={props.instanceId}
              scale={3}
            />
          </div>
          <div className='flex-auto pt-[2px]'>
            Landesbetrieb für Gebäudebewirtschaftung Berlin
            <br />
            Betriebsteil B (LfG-B)
            <br />
            {buildingLabel}
          </div>
        </div>
        <img className={styles.logo} src={logo} alt='' />
        <div className='text-base font-bold pt-3'>
          Datenschutzrechtliche Information und Einwilligungserklärung nach DSGVO
        </div>
        <div className='text-justify' style={{ fontSize: '13px', lineHeight: '18px' }}>
          Der Landesbetrieb für Gebäudebewirtschaftung Betriebsteil B (LfG-B) erhebt gemäß
          AsylG §7* personenbezogene Daten zur Sicherung der Unterbringung, Versorgung und
          Betreuung zugewiesener Personen innerhalb der Einrichtung. Während Ihres
          Aufenthaltes und dem Ihrer Kinder werden vom LfG-B folgende Daten erhoben und
          verarbeitet:{' '}
          <ul className='mt-1 list-disc ml-4'>
            <li>Personendaten</li>
            <li>Gesundheitsdaten inkl. Schutzbedürftigkeit und Impfstatus</li>
            <li>Bewohner-Ausweis-Foto</li>
            <li>Zuweisende Behörde/ Kostenträger sowie zugehörige Daten</li>
            <li>Datum der polizeilichen Anmeldung</li>
            <li>
              Zutrittsdaten, Verweildauer & Abwesenheitsdaten sowie zugehörige Nachweise
            </li>
            <li>Protokolle des Sozialdienstes im Zuge der Dokumentationspflicht</li>
            <li>
              Protokolle der Verwaltung und Einrichtungsleitung bzgl. Sanktionen wegen
              Verstoßes gegen die Hausordnung
            </li>
          </ul>
          <p className='mt-3'>
            Die Löschung Ihrer Daten erfolgt innerhalb von sechs Monaten nach Ihrem Auszug
            aus der Unterkunft. Ausnahme bilden abrechnungsrelevante Daten (Name, Vorname,
            Geburtsdatum, zuweisende Behörde, Kostenübernahmedokumente, Ein- und
            Auszugsdatum, Aufenthaltszeitraum sowie zugehörige Nachweise) die den
            gesetzlichen Aufbewahrungs- und Nachweispflichten unterliegen. Diese werden
            nach Ablauf des jeweils gesetzlich einschlägigen Aufbewahrungszeitraumes
            gelöscht.
          </p>
          <div
            className='pt-3 text-justify'
            style={{ fontSize: '13px', lineHeight: '18px' }}
          >
            <p>
              Neben diesen für Ihre Betreuung erforderlichen Daten bitte wir um Ihre{' '}
              <b>Einwilligung in die Datennutzung zu weiteren Zwecken:</b>
            </p>
            <p className='mt-2'>
              Sind Sie mit den folgenden Nutzungszwecken einverstanden, kreuzen Sie diese
              bitte entsprechend an. Wollen Sie keine Einwilligung erteilen, lassen Sie
              die Felder bitte frei.
            </p>

            <div className='flex mt-8'>
              <div className='flex-[0_0_50px] text-lg -mt-1'>☐</div>
              <div className='flex-auto'>
                Ich willige ein, dass meine persönlichen Kontaktdaten (z.B. E-Mail
                und/oder Telefonnummer) erhoben und gespeichert werden. Diese dienen
                ausschließlich der Kommunikation zwischen mir und dem LfG-B und werden
                ohne meine ausdrückliche Zustimmung nicht an Dritte weitergegeben.
              </div>
            </div>

            <div className='flex mt-3'>
              <div className='flex-[0_0_50px] text-lg -mt-1'>☐</div>
              <div className='flex-auto'>
                Ich willige ein, dass zu betrieblichen Zwecken der Betreuung in der
                Unterkunft auch Daten gemäß Art. 9 DSGVO (z.B. ethnische Herkunft,
                politische Meinung, religiöse Überzeugung, Gesundheitsdaten) über mich und
                meine Kinder erhoben, verarbeitet und zur Abstimmung des Hilfeprozesses an
                das Landes- amt für Flüchtlingsangelegenheiten, Bezirksämter, Jugendämter,
                Soziale Wohnhilfen, Jobcenter und medizi- nische Einrichtungen
                (niedergelassene Ärzte, Krankenhäuser) weitergeleitet werden dürfen.
              </div>
            </div>

            <div className='flex mt-3'>
              <div className='flex-[0_0_50px] text-lg -mt-1'>☐</div>
              <div className='flex-auto'>
                Ich willige ein, dass von mir und meinen Kindern Daten bezüglich meines
                Alters, Geschlechtes, Familien- standes, meiner Sprachkenntnisse,
                Interessen und Hobbies ausschließlich zum Zweck der Organisation von
                Förder- und Hilfsangeboten erhoben, verarbeitet und an entsprechende
                Partner-Organisationen wei- tergeleitet werden dürfen.
              </div>
            </div>

            <div className='flex mt-3'>
              <div className='flex-[0_0_50px] text-lg -mt-1'>☐</div>
              <div className='flex-auto'>
                Ich willige ein, dass ich und meine Kinder auf Fotos und Videos, die
                innerhalb der Wohneinrichtung im laufenden Betrieb und bei öffentlichen
                Veranstaltungen von Mitarbeitenden des LfG-B gemacht werden zu sehen sind.
                (Dies bedeutet kein Einverständnis in eine Veröffentlichung.)
              </div>
            </div>
            <div className='mt-4 italic'>
              Rechte der*des Betroffenen: Auskunft, Berichtigung, Löschung und Sperrung,
              Widerspruchsrecht{' '}
              <span className='mx-1'>
                <i className='fa fa-arrow-right' />
              </span>{' '}
              siehe S. 2
            </div>
          </div>
          {/* Signature */}
          <div className='grid gap-16 grid-cols-3 mt-4'>
            <div>
              <div className='border-b-2 border-black'>
                Berlin, {dayjs().format('DD.MM.YYYY')}
              </div>
              <div style={{ fontSize: '10px', lineHeight: '14px' }}>Ort, Datum</div>
            </div>

            <div className='w-[230px]'>
              <div className='border-b-2 border-black truncate'>
                {props.resident.lastName}, {props.resident.firstName}&nbsp;
              </div>
              <div style={{ fontSize: '10px', lineHeight: '14px' }}>
                Name Unterkunftsnehmer*in in Druckbuchstaben
              </div>
            </div>

            <div>
              <div className='border-b-2 border-black truncate'>&nbsp;</div>
              <div style={{ fontSize: '10px', lineHeight: '14px' }}>
                Unterschrift Unterkunftsnehmer*in
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-center items-center mt-8'>
            <div className='border-b-2 border-black truncate w-[200px]'>&nbsp;</div>
            <div
              style={{ fontSize: '10px', lineHeight: '14px' }}
            >{`${props.user.lastName}, ${props.user.firstName}`}</div>
          </div>
          <div
            className='flex justify-between w-[640px] text-xs text-gray-500 absolute'
            style={{ bottom: 40, fontSize: 9 }}
          >
            <div>Stand 09.08.2022</div>
            <div className='text-right'>
              Brunnenstraße 110 C, 13355 Berlin
              <br />
              Tel. +49 30 213 099 101
              <br />
              info@lfg-b.de
            </div>
          </div>
        </div>
      </div>
      {/* Page 2/2 */}
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <div className='absolute top-[40px] left-[100px] flex-[0_0_84px] mr-10 bg-white overflow-hidden'>
          <QRCode
            qrCodeId={props.model.values.qrCode || '0'}
            pageNumber={2}
            totalNumberOfPages={2}
            instanceId={props.instanceId}
            scale={3}
          />
        </div>
        <div className='text-base font-bold mt-3'>
          Rechte des Betroffenen: Auskunft, Berichtigung, Löschung und Sperrung,
          Widerspruchsrecht
        </div>
        <div className='pt-2' style={{ fontSize: '13px', lineHeight: '18px' }}>
          <p>
            Sie sind gemäß Artikel 15 DSGVO jederzeit berechtigt, gegenüber dem LfG-B um
            Auskunftserteilung zu den zu Ihrer Person gespeicherten Daten zu ersuchen.
          </p>
          <p className='mt-4'>
            Gemäß Artikel 17 DSGVO können Sie jederzeit gegenüber dem LfG- die
            Berichtigung, Löschung und Sperrung einzelner personenbezogener Daten
            verlangen.
          </p>
          <p className='mt-4'>
            Sie können darüber hinaus jederzeit ohne Angabe von Gründen von Ihrem
            Widerspruchsrecht Gebrauch machen und die erteilte Einwilligungserklärung mit
            Wirkung für die Zukunft abändern oder gänzlich widerrufen. Sie können den
            Widerruf entweder postalisch, per E-Mail oder per Fax an den Vertragspartner
            übermitteln:
          </p>

          <div className='flex mt-7'>
            <div className='flex-content mr-12'>Datenschutzbeauftragter:</div>
            <div className='flex-auto'>
              Herr Gregor Klar
              <br />
              Landesbetrieb für Gebäudebewirtschaftung
              <br />
              Betriebsteil B<br />
              Brunnenstraße 110c
              <br />
              13355 Berlin
              <br />
              datenschutz@lfg-b.de
              <br />
              Fax 030 213 099 029
              <br />
            </div>
          </div>

          <div className='pt-8'>
            Gemäß Artikel 77 DSGVO sind Sie berechtigt Beschwerde bei einer
            Aufsichtsbehörde einzulegen sofern Sie die Ansicht vertreten, dass Ihre Daten
            rechtswidrig verarbeitet wurden.
          </div>
        </div>

        <p
          className='border border-black p-2 mt-12 text-left text-xs'
          style={{ lineHeight: '19px' }}
        >
          * Asylgesetz (AsylG) § 7 Erhebung personenbezogener Daten
          <br />
          (1) Die mit der Ausführung dieses Gesetzes betrauten Behörden dürfen zum Zwecke
          der Ausführung dieses Gesetzes personenbezogene Daten erheben, soweit dies zur
          Erfüllung ihrer Aufgaben erforderlich ist. Personenbezogene Daten, deren
          Verarbeitung nach Artikel 9 Absatz 1 der Verordnung (EU) 2016/679 des
          Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher
          Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr
          und zur Aufhebung der Richtlinie 95/46/EG (Daten-schutz-Grundverordnung) (ABl. L
          119 vom 4.5.2016, S. 1; L 314 vom 22.11.2016, S. 72; L 127 vom 23.5.2018, S. 2)
          in der jeweils geltenden Fassung untersagt ist, dürfen erhoben werden, soweit
          dies im Einzelfall zur Aufgabenerfüllung erforderlich ist.
          <br />
          (2) Die Daten sind bei der betroffenen Person zu erheben. Sie dürfen auch ohne
          Mitwirkung der betroffenen Person bei anderen öffentlichen Stellen,
          ausländischen Behörden und nichtöffentlichen Stellen erhoben werden, wenn
          <br />
          1. dieses Gesetz oder eine andere Rechtsvorschrift es vorsieht oder zwingend
          voraussetzt,
          <br />
          2. es offensichtlich ist, dass es im Interesse der betroffenen Person liegt und
          kein Grund zu der Annahme besteht, dass sie in Kenntnis der Erhebung ihre
          Einwilligung verweigern würde,
          <br />
          3. die Mitwirkung der betroffenen Person nicht ausreicht oder einen
          unverhältnismäßigen Aufwand erfordern würde,
          <br />
          4. die zu erfüllende Aufgabe ihrer Art nach einer Erhebung bei anderen Personen
          oder Stellen erforderlich macht oder
          <br />
          5. es zur Überprüfung der Angaben der betroffenen Person erforderlich ist.
          <br />
          Nach Satz 2 Nr. 3 und 4 sowie bei ausländischen Behörden und nichtöffentlichen
          Stellen dürfen Daten nur erhoben werden, wenn keine Anhaltspunkte dafür
          bestehen, dass überwiegende schutzwürdige Interessen der betroffenen Person
          beeinträchtigt werden.
        </p>

        {/* Footer */}
        <div
          className='text-xs absolute'
          style={{ bottom: 40, right: '100px', fontSize: 9 }}
        >
          Seite 2 von 2
        </div>
      </div>
    </>
  )
}
