import { InputCountry } from 'components/Form/components/InputCountry'
import { InputNationality } from 'components/Form/components/InputNationality'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { Model } from 'components/Form/Model'
import { IMapping } from 'contracts/general/interfaces/IMapping'
import { IMigration } from 'contracts/general/interfaces/IMigration'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  label: string
  type: string
  row: IMapping
  options?: InputSelectOption[]
}

export class MappingRow extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<IMigration>

  constructor(props: Props) {
    super(props)
    this.model = new Model<IMigration>(props.row)
  }

  render() {
    return (
      <div className='flex p-2 bg-white shadow rounded-md mb-3'>
        <div className='flex-[220px] max-w-[220px] truncate'>{this.props.row.from}</div>
        <div className='flex-auto'>
          {this.props.type === 'country' && (
            <InputCountry model={this.model} name='to' label='Land' />
          )}
          {this.props.type === 'nationality' && (
            <InputNationality model={this.model} name='to' label='Nationalität' />
          )}
          {this.props.type === 'enum' && this.props.options && (
            <InputSelect
              model={this.model}
              name='to'
              label={this.props.label}
              options={this.props.options}
            />
          )}
        </div>
      </div>
    )
  }
}
