import axios from 'axios'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { Spinner } from 'components/Spinner'
import { IEmployeeCommMessage } from 'contracts/employeeComm/interfaces/IEmployeeCommMessage'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  token: string
  instanceId: string
}

@observer
export class EmploiyeeCommunicationReceiver extends React.Component<Props, {}> {
  @observable private message: IEmployeeCommMessage | null = null
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    // Load message with token -> Needs to mark as read
    void this.loadThread()

    // Ask dob if necessary. -> Mark token as valid
  }

  private loadThread = async () => {
    try {
      const response = await axios.get<IEmployeeCommMessage>(
        `/api/${this.props.instanceId}/employeeComm/messages/${this.props.token}`,
      )
      runInAction(() => (this.message = response.data))
    } catch (e: any) {
      if (e?.response?.data?.id === ConflictError.id) {
        runInAction(() => (this.error = e?.response?.data?.message))
      } else {
        runInAction(
          () =>
            (this.error =
              'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'),
        )
      }
    }
  }

  private downloadAttachment = (documentId?: string) => {
    if (!this.message) {
      return
    }
    if (!this.message.code) {
      const answer = window.prompt(
        'Bitte geben Sie die letzten 6 Ziffern Ihrer IBAN ein, um Ihre Identität zu bestätigen.',
      )
      if (
        !answer ||
        answer.replace(/[^0-9 ]/g, '') !== answer ||
        answer.replace(/[^0-9]/g, '').length !== 6
      ) {
        runInAction(
          () =>
            (this.error =
              'Die letzten 6 Ziffern Ihrer IBAN stimmen nicht überein. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.'),
        )
        return
      }
      this.message.code = answer.replace(/[^0-9]/g, '')
    }
    if (documentId) {
      window.location.href = `/api/${this.props.instanceId}/employeeComm/messageAttachments/${this.message.code}-${this.props.token}-${documentId}`
    } else {
      window.location.href = `/api/${this.props.instanceId}/employeeComm/messageAttachments/${this.message.code}-${this.props.token}`
    }
  }

  render() {
    if (this.error) {
      return (
        <div className='p-6'>
          <Message color='danger'>{this.error}</Message>
        </div>
      )
    }

    if (!this.message) {
      return (
        <div>
          <Spinner />
        </div>
      )
    }

    return (
      <div className='p-6'>
        <Message color='primary'>
          <b>{`Betreff: ${this.message.subject}`}</b>
          <br />
          {this.message.body}
          <br />
          <br />
          <Button onClick={() => this.downloadAttachment()} color='primary'>
            Herunterladen
          </Button>
        </Message>

        {this.message.type === 'salary slip' && this.message.attachments.length > 1 && (
          <div className='mt-6 rounded-md p-4 bg-indigo-50 text-sm leading-6'>
            <div className='font-bold'>Ältere Gehaltszettel</div>
            <div className='grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-3 mt-2'>
              {this.message.attachments.map((a, i) => {
                if (i === 0) {
                  return null
                }
                return (
                  <Button
                    key={i}
                    onClick={() => this.downloadAttachment(a.id)}
                    color='primary'
                  >
                    {a.name}{' '}
                    <span>
                      <i className='fas fa-download' />
                    </span>
                  </Button>
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }
}
