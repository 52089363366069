import { encodeQrCode } from 'contracts/general/helpers/encodeQrCode'
import { Wait } from 'modules/Pdf/components/Wait'
import * as React from 'react'
const QRCodeGenerator = require('qrcode')

interface Props {
  qrCodeId: string
  instanceId: string
  pageNumber: number // Default 1
  totalNumberOfPages: number // Default 1
  scale?: number
}

export class QRCode extends React.Component<Props, { done: boolean }> {
  constructor(props: Props) {
    super(props)
    this.state = { done: false }
  }

  private ref = (el) => {
    QRCodeGenerator.toCanvas(
      el,
      encodeQrCode(
        this.props.qrCodeId,
        this.props.pageNumber,
        this.props.totalNumberOfPages,
        this.props.instanceId,
      ),
      {
        margin: 0,
        error: 'H',
        version: 1,
        mode: 'numeric',
        scale: this.props.scale || 4,
      },
      (error) => {
        this.setState({ done: !error })
      },
    )
  }

  render() {
    return (
      <>
        <canvas ref={this.ref} style={{ width: 84, height: 84 }} />
        {!this.state.done && <Wait />}
      </>
    )
  }
}
