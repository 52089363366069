import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import axios from 'axios'
import { Wait } from 'modules/Pdf/components/Wait'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { QRCode } from 'components/QrCode'

export const LfgbSchluesselprotokoll: React.FC<DocumentTemplateProps> = (props) => {
  const [residentSearchResult, setResidentSearchResult] =
    React.useState<IResidentSearchResult | null>(null)
  const [building, setBuilding] = React.useState<IBuilding | null>(null)
  const [compound, setCompound] = React.useState<ICompound | null>(null)

  React.useEffect(() => {
    const load = async () => {
      const [r] = await Promise.all([
        axios.get<any>(
          `/api/${props.resident.instanceId}/residentSearchResults?residentIds=${props.resident.id}&fields=bookings`,
        ),
      ])
      if (!Array.isArray(r.data.resources) || r.data.resources.length !== 1) {
        throw new Error('Not found')
      }
      const stay = r.data.resources[0]?.data.bookings?.find(
        (b) => b.timeline === 'present' && b.type === 'internal-residence',
      )
      if (stay) {
        const rm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
        )
        const bl = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
        )
        const cp = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
        )
        setBuilding(bl.data)
        setCompound(cp.data)
      }
      setResidentSearchResult(r.data.resources[0])
    }
    load()
    // eslint-disable-next-line
  }, [])

  let buildingLabel = ''

  if (compound?.label === building?.label) {
    buildingLabel = compound?.label || ''
  } else {
    buildingLabel = compound && building ? compound?.label + ' / ' + building?.label : ''
  }

  if (!residentSearchResult) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <Wait />
      </div>
    )
  }

  const rows = [...Array(12).keys()]
  return (
    <>
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        {/* Header */}
        <div className='absolute top-[40px] left-[60px] right-[60px] flex'>
          <div className='flex-[0_0_100px]'>
            <QRCode
              qrCodeId={props.model.values.qrCode || '0'}
              pageNumber={1}
              totalNumberOfPages={1}
              instanceId={props.instanceId}
              scale={3}
            />
          </div>
          <div className='flex-auto pt-[2px]' style={{ fontSize: '13px' }}>
            Landesbetrieb für Gebäudebewirtschaftung Berlin
            <br />
            Betriebsteil B (LfG-B)
            <br />
            {buildingLabel}
          </div>
        </div>
        <img className={styles.logo} src={logo} alt='' />

        {/* Title */}
        <div className='text-2xl text-center mt-10'>Schlüsselprotokoll</div>

        <div className='mt-2'>
          <b>Name:</b> {`${props.resident.firstName} ${props.resident.lastName}`}
        </div>

        {/* Table */}
        <table className='mt-2 border-2 border-black border-collapse text-sm w-full'>
          <thead>
            <tr className='bg-gray-300 font-bold text-center'>
              <td className='border-2 border-black' colSpan={3}>
                Schlüssel oder Karte
              </td>
              <td className='border-2 border-black' colSpan={3}>
                Ausgabe
              </td>
              <td className='border-2 border-black' colSpan={4}>
                Rückgabe bzw. Verlust
              </td>
            </tr>
            <tr className='bg-gray-200 text-center text-xs'>
              <td className='px-1 border-b-2 border-r border-black'>
                Zimmer-
                <br />
                Nr.
              </td>
              <td className='px-1 border-b-2 border-r border-black'>
                Nummer
                <br /> von Schlüssel bzw. Karte
              </td>
              <td className='px-1 border-b-2 border-r-2 border-black'>Anzahl</td>
              <td className='px-1 border-b-2 border-r border-black w-[80px]'>Datum</td>
              <td className='px-1 border-b-2 border-r border-black w-[125px]'>
                Unterschrift
                <br /> BW
              </td>
              <td className='px-1 border-b-2 border-r-2 border-black w-[125px]'>
                Unterschrift
                <br /> LfG-B-MA
              </td>
              <td className='px-1 border-b-2 border-r border-black w-[80px]'>Datum</td>
              <td className='px-1 border-b-2 border-r border-black'>
                Grund
                <br /> z.B. Verlust oder Auszug
              </td>
              <td className='px-1 border-b-2 border-r border-black w-[125px]'>
                Unterschrift
                <br /> BW
              </td>
              <td className='px-1 border-b-2 border-black w-[125px]'>
                Unterschrift
                <br /> LfG-B-MA
              </td>
            </tr>
          </thead>
          <tbody>
            {rows.map((_row, i) => (
              <tr key={i}>
                <td className='px-1 py-2 border border-black'>&nbsp;</td>
                <td className='px-1 border border-black'>&nbsp;</td>
                <td className='px-1 border border-r-2 border-black'>&nbsp;</td>
                <td className='px-1 border border-black'>&nbsp;</td>
                <td className='px-1 border border-black'>&nbsp;</td>
                <td className='px-1 border border-r-2 border-black'>&nbsp;</td>
                <td className='px-1 border border-black'>&nbsp;</td>
                <td className='px-1 border border-black'>&nbsp;</td>
                <td className='px-1 border border-black'>&nbsp;</td>
                <td className='px-1 border border-black'>&nbsp;</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Footer */}
        <div
          className='flex justify-between w-[1068px] text-xs text-gray-500 absolute'
          style={{ bottom: 40, fontSize: 9 }}
        >
          <div>Stand 09.08.2022</div>
          <div className='text-right'>
            Brunnenstraße 110 C, 13355 Berlin
            <br />
            Tel. +49 30 213 099 101
            <br />
            info@lfg-b.de
          </div>
        </div>
      </div>
    </>
  )
}
